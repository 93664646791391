import { OrganizationPreview, OrganizationProfile, OrganizationSimple } from '@borg/types';

type CardInput = OrganizationProfile | OrganizationPreview | OrganizationSimple | 'incognito';

export const useOrganizationCard = createInjector('use-organization-card', () => {
  const organization = ref<OrganizationProfile | 'incognito'>();

  function set(item: CardInput) {
    if (item === 'incognito') {
      organization.value = item;
    } else if ('content' in item) {
      organization.value = item;
    } else {
      organization.value = {
        content: '',
        web: '',
        hasProfile: false,
        ...item,
      };
    }
  }

  return reactive({
    organization,
    set,
  });
});
